<template>
  <div class="card border-0">
    <div class="card-header f-w-600">
      <vxe-button
        class="m-r-20"
        status="primary"
        content="新增"
        @click="insertEvent()"
      ></vxe-button>
      安全管理人员
    </div>
    <div class="card-body height-auto m-0 p-0">
      <form method="POST">
        <!-- panel-body begin -->
        <!-- begin register -->
        <div class="panel-body">
          <vxe-table
            border
            resizable
            show-overflow
            :loading="loading"
            keep-source
            ref="xTable"
            height="350"
            width="100%"
            :data="tableData"
            :edit-rules="validRules"
            :layouts="[
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'FullJump',
              'Sizes',
              'Total',
            ]"
            :edit-config="{
              trigger: 'click',
              mode: 'row',
              showUpdateStatus: true,
              showInsertStatus: true,
            }"
          >
            <vxe-column type="checkbox" width="60"></vxe-column>
            <vxe-column field="name" title="姓名" :edit-render="{}" width="100">
              <template #edit="{ row }">
                <vxe-input v-model="row.name" type="text"></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="idCard"
              title="身份证"
              :edit-render="{}"
              width="150"
            >
              <template #edit="{ row }">
                <vxe-input v-model="row.idCard" type="text"></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="certSituation"
              title="持证情况"
              :edit-render="{}"
              width="200"
            >
              <template #default="{ row }">
                <span>{{ row.certSituation }}</span>
              </template>
              <template #edit="{ row }">
                <vxe-select
                  v-model="row.certSituation"
                  type="text"
                  transfer
                >
                  <vxe-option value="无" label="无">无</vxe-option>
                  <vxe-option value="安全生产管理人员证书" label="安全生产管理人员证书">安全生产管理人员证书</vxe-option>
                  <vxe-option value="初级注册安全生产工程师" label="初级注册安全生产工程师">初级注册安全生产工程师</vxe-option>
                  <vxe-option value="中级注册安全生产工程师" label="中级注册安全生产工程师">中级注册安全生产工程师</vxe-option>
                  <vxe-option value="高级注册安全生产工程师" label="高级注册安全生产工程师">高级注册安全生产工程师</vxe-option>
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column field="tel" title="电话" :edit-render="{}" width="140">
              <template #edit="{ row }">
                <vxe-input v-model="row.tel"></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="cardValidity"
              title="证书有效期"
              :edit-render="{}"
              width="200"
            >
              <template #edit="{ row }">
                <vxe-input v-model="row.cardValidity" type="date"></vxe-input>
              </template>
            </vxe-column>

            <vxe-column
              title="证书号"
              width="170"
              field="cardNum"
              :edit-render="{}"
            >
              <template #edit="{ row }">
                <vxe-input v-model="row.cardNum" type="int"></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="isMain"
              title="是否是负责人"
              :edit-render="{}"
              width="130"
            >
              <template #default="{ row }">
                <span>{{ row.isMain ? "是" : "否" }}</span>
              </template>
              <template #edit="{ row }">
                <vxe-select
                  v-model="row.isMain"
                  type="text"
                  :options="certSituationList"
                  transfer
                ></vxe-select>
              </template>
            </vxe-column>
            <vxe-column title="企业id" field="businessId" :visible="false">
              <template #edit="{ row }">
                <vxe-input v-model="row.businessId"></vxe-input>
              </template>
            </vxe-column>
            <vxe-column title="安全检查员ID" field="id" :visible="false">
              <template #edit="{ row }">
                <vxe-input v-model="row.id"></vxe-input>
              </template>
            </vxe-column>
            <vxe-column title="操作" fixed="right" width="190">
              <template #default="{ row }">
                <vxe-button
                  status="warning"
                  content="删除"
                  @click="removeRowEvent(row)"
                ></vxe-button>
                <vxe-button
                  status="success"
                  content="保存"
                  @click="saveEvent(row)"
                ></vxe-button>
              </template>
            </vxe-column>
          </vxe-table>
          <vxe-pager
            border
            icon-prev-page="fa fa-angle-left"
            icon-jump-prev="fa fa-angle-double-left"
            icon-jump-next="fa fa-angle-double-right"
            icon-next-page="fa fa-angle-right"
            icon-jump-more="fa fa-ellipsis-h"
            :loading="loading"
            :current-page="tablePage.currentPage"
            :page-size="tablePage.pageSize"
            :total="tablePage.totalResult"
            :layouts="[
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'FullJump',
              'Sizes',
              'Total',
            ]"
            @page-change="handlePageChange"
          >
          </vxe-pager>
        </div>
      </form>
    </div>
    <div class="card-footer text-right f-w-600">
      <!-- <a @click="onOK" type="submit" class="btn btn-sm btn-primary m-r-5"> 确定 </a> -->
      <!-- <button type="submit" @click="checkForm" class="btn btn-sm btn-default">
          确定
        </button> -->
      <button
        type="button"
        @click="$emit('close')"
        class="btn btn-sm btn-default"
      >
        ❌关闭
      </button>
    </div>
  </div>
</template>
<script>
import { businessApi } from "@/api/system/businessApi";

export default {
  props: ["OfficerListid"],
  data() {
    return {
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      tableData: [],
      loading: false,
      validRules: {
        Name: [{ required: true, message: "名称必须填写" }],
      },
      certSituationList: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
    };
  },
  created() {
    console.log(this.OfficerListid);
  },
  mounted() {
    if (this.OfficerListid) {
      this.GetOfficerList(this.OfficerListid);
    }
  },
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    //获取安全管理员列表
    GetOfficerList(id) {
      let that = this;
      that.loading = true;
      businessApi
        .GetOfficerList({
          //入参为地址栏传入的ID值
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize,
          MaxResultCount: that.tablePage.pageSize,
          Sorting: "",
          BusinessId: id,
        })
        .then((res) => that.GetOfficerListSuccess(that, res))
        .finally(() => {
          that.loading = false;
        });
    },
    GetOfficerListSuccess(that, res) {
      if (res.success) {
        that.tableData = res.data.items;
        // that.tableData.isMain ? "是" : "否";
        that.tablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    //安全员列表新增
    async insertEvent() {
      const $table = this.$refs.xTable;
      const newRecord = {
        BusinessId: this.OfficerListid,
        Name: "",
        IdCard: "",
        Tel: "",
        certSituation: "无",
        CardNum: "",
        CardValidity: "",
        IsMain: true,
      };
      const { row: newRow } = await $table.insert(newRecord);
      await $table.setActiveRow(newRow);
    },
    saveEvent(row) {
      const tableData = {
        businessId: this.OfficerListid,
        name: row.name,
        idCard: row.idCard,
        tel: row.tel,
        certSituation: row.certSituation,
        cardNum: row.cardNum,
        cardValidity: row.cardValidity,
        isMain: row.isMain,
      };
      const $table = this.$refs.xTable;
      // var t=$table;
      console.log(row);
      let res = $table.getRecordset();
      let that = this;
      if (row.id) {
        businessApi
          .UpdataOfficer(row)
          .then((res) => that.UpdataOfficerSuccess(that, res))
          .finally(() => {});
      } else {
        businessApi
          .Createsecurityofficer(tableData)
          .then((res) => that.CreateofficerSuccess(that, res))
          .finally(() => {});
        console.log(that);
      }
      if (
        res.insertRecords.length <= 0 &&
        res.removeRecords.length <= 0 &&
        res.updateRecords.length <= 0
      ) {
        that.$XModal.message({ content: "数据未改动！", status: "warning" });
        return;
      }
      const errMap = $table.validate().catch((errMap) => errMap);
      if (errMap) {
        return;
      }
    },
    removeRowEvent(row) {
      this.$swal({
        // title: "Are you sure?",
        text: "是否确定删除?",
        type: "info",
        icon: "warning",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            if (row.id) {
              businessApi
                .DeleteOfficer({ id: row.id })
                .then((res) => that.DeleteOfficerSuccess(that, res, row))
                .finally(() => {});
            } else {
              const $table = this.$refs.xTable;
              $table.remove(row);
            }
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    CreateofficerSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        that.ToastWarning(res);
      }
    },
    UpdataOfficerSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        that.ToastWarning(res);
      }
    },
    DeleteOfficerSuccess(that, res, row) {
      if (res.success) {
        const $table = this.$refs.xTable;
        $table.remove(row);
        res.message = "删除成功";
        that.ToastSuccess(res);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      //改变分页参数，重新请求Api
      this.GetOfficerList();
    },
  },
};
</script>
