var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card border-0"},[_c('div',{staticClass:"card-header f-w-600"},[_c('vxe-button',{staticClass:"m-r-20",attrs:{"status":"primary","content":"新增"},on:{"click":function($event){return _vm.insertEvent()}}}),_vm._v(" 安全管理人员 ")],1),_c('div',{staticClass:"card-body height-auto m-0 p-0"},[_c('form',{attrs:{"method":"POST"}},[_c('div',{staticClass:"panel-body"},[_c('vxe-table',{ref:"xTable",attrs:{"border":"","resizable":"","show-overflow":"","loading":_vm.loading,"keep-source":"","height":"350","width":"100%","data":_vm.tableData,"edit-rules":_vm.validRules,"layouts":[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total' ],"edit-config":{
            trigger: 'click',
            mode: 'row',
            showUpdateStatus: true,
            showInsertStatus: true,
          }}},[_c('vxe-column',{attrs:{"type":"checkbox","width":"60"}}),_c('vxe-column',{attrs:{"field":"name","title":"姓名","edit-render":{},"width":"100"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}])}),_c('vxe-column',{attrs:{"field":"idCard","title":"身份证","edit-render":{},"width":"150"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.idCard),callback:function ($$v) {_vm.$set(row, "idCard", $$v)},expression:"row.idCard"}})]}}])}),_c('vxe-column',{attrs:{"field":"certSituation","title":"持证情况","edit-render":{},"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.certSituation))])]}},{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-select',{attrs:{"type":"text","transfer":""},model:{value:(row.certSituation),callback:function ($$v) {_vm.$set(row, "certSituation", $$v)},expression:"row.certSituation"}},[_c('vxe-option',{attrs:{"value":"无","label":"无"}},[_vm._v("无")]),_c('vxe-option',{attrs:{"value":"安全生产管理人员证书","label":"安全生产管理人员证书"}},[_vm._v("安全生产管理人员证书")]),_c('vxe-option',{attrs:{"value":"初级注册安全生产工程师","label":"初级注册安全生产工程师"}},[_vm._v("初级注册安全生产工程师")]),_c('vxe-option',{attrs:{"value":"中级注册安全生产工程师","label":"中级注册安全生产工程师"}},[_vm._v("中级注册安全生产工程师")]),_c('vxe-option',{attrs:{"value":"高级注册安全生产工程师","label":"高级注册安全生产工程师"}},[_vm._v("高级注册安全生产工程师")])],1)]}}])}),_c('vxe-column',{attrs:{"field":"tel","title":"电话","edit-render":{},"width":"140"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-input',{model:{value:(row.tel),callback:function ($$v) {_vm.$set(row, "tel", $$v)},expression:"row.tel"}})]}}])}),_c('vxe-column',{attrs:{"field":"cardValidity","title":"证书有效期","edit-render":{},"width":"200"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"date"},model:{value:(row.cardValidity),callback:function ($$v) {_vm.$set(row, "cardValidity", $$v)},expression:"row.cardValidity"}})]}}])}),_c('vxe-column',{attrs:{"title":"证书号","width":"170","field":"cardNum","edit-render":{}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"int"},model:{value:(row.cardNum),callback:function ($$v) {_vm.$set(row, "cardNum", $$v)},expression:"row.cardNum"}})]}}])}),_c('vxe-column',{attrs:{"field":"isMain","title":"是否是负责人","edit-render":{},"width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.isMain ? "是" : "否"))])]}},{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-select',{attrs:{"type":"text","options":_vm.certSituationList,"transfer":""},model:{value:(row.isMain),callback:function ($$v) {_vm.$set(row, "isMain", $$v)},expression:"row.isMain"}})]}}])}),_c('vxe-column',{attrs:{"title":"企业id","field":"businessId","visible":false},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-input',{model:{value:(row.businessId),callback:function ($$v) {_vm.$set(row, "businessId", $$v)},expression:"row.businessId"}})]}}])}),_c('vxe-column',{attrs:{"title":"安全检查员ID","field":"id","visible":false},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
          var row = ref.row;
return [_c('vxe-input',{model:{value:(row.id),callback:function ($$v) {_vm.$set(row, "id", $$v)},expression:"row.id"}})]}}])}),_c('vxe-column',{attrs:{"title":"操作","fixed":"right","width":"190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('vxe-button',{attrs:{"status":"warning","content":"删除"},on:{"click":function($event){return _vm.removeRowEvent(row)}}}),_c('vxe-button',{attrs:{"status":"success","content":"保存"},on:{"click":function($event){return _vm.saveEvent(row)}}})]}}])})],1),_c('vxe-pager',{attrs:{"border":"","icon-prev-page":"fa fa-angle-left","icon-jump-prev":"fa fa-angle-double-left","icon-jump-next":"fa fa-angle-double-right","icon-next-page":"fa fa-angle-right","icon-jump-more":"fa fa-ellipsis-h","loading":_vm.loading,"current-page":_vm.tablePage.currentPage,"page-size":_vm.tablePage.pageSize,"total":_vm.tablePage.totalResult,"layouts":[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total' ]},on:{"page-change":_vm.handlePageChange}})],1)])]),_c('div',{staticClass:"card-footer text-right f-w-600"},[_c('button',{staticClass:"btn btn-sm btn-default",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" ❌关闭 ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }